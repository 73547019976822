import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { map, timeout } from 'rxjs/operators';
import { InterfaceService } from './auth/interface.service';

@Injectable({
  providedIn: 'root'
})
export class PatientAssessmentService extends InterfaceService {

  constructor(private http: HttpClient, private router: Router) {
    super()
  }

  public verifyAssessmentTakeToken(data) {
    return this.http.post(this.getApiUrl(`take-assessments/verify-assessment-token/`), data, this.getHttpOptions('json', false)).pipe(
      map(Response => Response),
      timeout(10000)
    );
  }

 
  public updatePatientAssessmentAnswer(data) {
    return this.http.post(this.getApiUrl('take-assessments/update-patient-assessment-answer/'), data, this.getHttpOptions('json', true)).pipe(
      map(Response => Response),
      timeout(5000),
    );
  }

  public updatePatientAssessmentRunStatus(data) {
    let link = window.location.origin + this.router.createUrlTree([`assessments/detail-view/${data['patientAssessmentId']}`], { queryParams: { 'runIds': data['runIds'].map(function (elem) { return elem['id'] }) } }).toString();
    data['link'] = link
    return this.http.post(this.getApiUrl(`take-assessments/update-patient-assessment-run-status/`), data, this.getHttpOptions('json', true)).pipe(
      map(response => response),
      timeout(5000),
    );
  }

  public submitPatientAssessment(data) {
    let link = window.location.origin + this.router.createUrlTree([`assessments/detail-view/${data['patientAssessmentId']}`], { queryParams: { 'runIds': data['runIds'].map(function (elem) { return elem['id'] }) } }).toString();
    data['link'] = link
    return this.http.post(this.getApiUrl(`take-assessments/submit-patient-assessment/`), data, this.getHttpOptions('json', true)).pipe(
      map(response => response),
      timeout(10000),
    );
  }
}
