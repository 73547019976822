import { Component, ViewEncapsulation } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { LocationStrategy } from "@angular/common";
import * as moment from "moment";
import { PatientAssessmentService } from "../support/services/patient-assessment.service";
import { SnackbarCongiration } from "../shared/components/snack-bar/SnackbarConfiguration";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { ReloadComponent } from "../shared/components/dialog-box/reload/reload.component";

@Component({
  selector: "app-patient-assessment-link",
  templateUrl: "./patient-assessment-link.component.html",
  styleUrls: ["./patient-assessment-link.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PatientAssessmentLinkComponent {
  assessment_token: any = undefined;
  screenType: any = "";
  auth_data: any = undefined;
  answers: any;
  hasError: boolean = false;
  errorMessage: string;
  selectedIndex: any;
  showAssessment: any = false;
  assessmentData: any = undefined;
  currAssessmentIndex: any = 0;
  is_completed: boolean = false;
  assessment_code: FormControl = new FormControl("", [
    Validators.required,
    Validators.pattern("^[0-9]+$"),
  ]);
  assessment_code_value: any = null;
  assessment_take_payload = {};
  take_type: any;
  state$: any = undefined;
  language: any = "english";
  languages_enabled: Array<string> = [];
  tokenExpiry: any = undefined;
  retryCount = 0;
  assessmentTimeOutData: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private patientAssessmentService: PatientAssessmentService,
    private snackbar: SnackbarCongiration,
    private location: LocationStrategy,
    private dialog: MatDialog
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });

    this.assessmentDetails();
  }

  onAuthVerify(event) {
    let data = {};
    data = { ...data, ...event };
    data["token"] = this.assessment_token;
    data["take_type"] = this.take_type;
    data["language"] = this.language;
    this.verifyAssessment(data);
  }

  onAssessmentComplete(event) {
    this.is_completed = event;
  }

  assessmentDetails() {
    if (this.router.url.indexOf("?") > 0) {
      const arr = window.location.href.split("?");
      if (arr[0].includes("survey")) {
        this.route.queryParams.subscribe((params) => {
          if (params.token) {
            const token = params["token"];
            this.assessment_token = token;
            this.assessment_take_payload["token"] = this.assessment_token;
            arr[0].includes("device")
              ? (this.take_type = "device")
              : (this.take_type = "remote");
            this.assessment_take_payload["take_type"] = this.take_type;
            const verification_payload = new Object();
            verification_payload["token"] = this.assessment_token;
            verification_payload["take_type"] = this.take_type;
            verification_payload["language"] = this.language;
            if ("liability" in params) {
              verification_payload["liability_not_needed"] = true;
            }
            if ("formfactor" in params) {
              verification_payload["mobile"] = true;
            }
            const time_offset = new Date().getTimezoneOffset();
            verification_payload["time_offset"] = time_offset;
            this.verifyAssessment(verification_payload);
          }
        });
      }
    }
  }

  verifyAssessment(data) {
    this.assessmentTimeOutData = data;
    this.patientAssessmentService.verifyAssessmentTakeToken(data).subscribe(
      (response) => {
        if (response["status"]) {
          this.screenType = response["type"];
          if (this.screenType == "auth") {
            this.auth_data = response["auth_data"];
            if ("mobile" in data && data["mobile"]) {
              this.assessment_token = response["auth_data"]["mobile_token"];
              this.assessment_take_payload["token"] = this.assessment_token;
            }
          } else if (this.screenType == "assessment") {
            if ("mobile" in data && data["mobile"]) {
              if ("mobile_token" in response) {
                this.assessment_token = response["mobile_token"];
              }
              this.assessment_take_payload["token"] = this.assessment_token;
            }
            this.assessmentData = response["assessment_data"];
            this.language = this.assessmentData["language"];
            this.languages_enabled = this.assessmentData["languages_enabled"];
            this.tokenExpiry = moment(
              this.assessmentData["token_expiry"]
            ).format("LL hh:mm a");
          }
        } else {
          this.snackbar.triggerSnackBar({
            message: response["message"],
            type: "error",
          });
        }
      },
      (error) => {
        let errorMessage;
        let buttonType;
        this.hasError = true;
        if (error === "Timeout has occurred") {
          if (this.retryCount < 3) {
            errorMessage = {
              english:
                "We're having trouble processing your last response. Please try again, and if the issue persists, contact support for assistance.",
              spanish:
                "Estamos teniendo problemas para procesar su última respuesta. Por favor, inténtelo de nuevo, y si el problema persiste, comuníquese con el soporte para obtener ayuda.",
            };
            this.retryCount += 1;
            buttonType = "Retry";
          } else {
            errorMessage = {
              english:
                "We're sorry, but we are still experiencing issues with your connection. Please restart your assessment(s).",
              spanish:
                "Lo sentimos, pero seguimos teniendo problemas con su conexión. Por favor, reinicie su(s) evaluación(es).",
            };
            buttonType = "Close";
          }
        } else if (error.status) {
          switch (error.status) {
            case 400:
              if (error.error.completed) {
                errorMessage = {
                  english: `The assessment(s) have already been completed.`,
                  spanish: `La(s) evaluación(es) ya ha(n) sido completada(s).`,
                };
              } else {
                errorMessage = {
                  english:
                    "The link you used is not valid. Please verify the link and try again, or reach out to support for assistance.",
                  spanish:
                    "El enlace que utilizó no es válido. Por favor, verifique el enlace e inténtelo de nuevo, o comuníquese con el soporte para obtener ayuda.",
                };
              }

              break;
            case 401:
              errorMessage = {
                english: `We're sorry, but the assessment link you've just clicked has expired and is no longer active.`,
                spanish: `Lo sentimos, pero el enlace de evaluación en el que acaba de hacer clic ha caducado y ya no está activo.`,
              };

              break;
            case 403:
              errorMessage = {
                english: `We're sorry, but the assessment has been updated. Please refresh the page to load the latest version.`,
                spanish: `Lo sentimos, pero la evaluación ha sido actualizada. Por favor, actualice la página para cargar la versión más reciente.`,
              };
              buttonType = "Refresh";
              break;
            default:
              errorMessage = {
                english: `An unexpected error occurred. Please try again later.`,
                spanish: `Ocurrió un error inesperado. Por favor, inténtelo de nuevo más tarde.`,
              };
          }
        }
        const attentionText = {
          english: "Attention",
          spanish: "Atención",
        };
        const data = {
          attentionText: attentionText[this.language],
          errorMessage: errorMessage[this.language],
          buttonType,
        };
        this.retryDialog(data);
      }
    );
  }

  retryDialog(data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "450px";
    dialogConfig.disableClose = true;
    dialogConfig.data = data;
    const dialogRef = this.dialog.open(ReloadComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((value) => {
      if (value == "Retry") {
        this.verifyAssessment(this.assessmentTimeOutData);
      }

      if (value == "Refresh") {
        window.location.reload();
      }
    });
  }
}
